import { MenuItem, useToast } from "@chakra-ui/react";
import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteUser } from "../apiCalls/user";

const DeleteUser = ({ id }) => {
  const toast = useToast();
  const cache = useQueryClient();

  const deleteUserMutation = useMutation({
    mutationKey: ["deleteUser"],
    mutationFn: deleteUser,
    onSettled: () => {
      cache.invalidateQueries("users");
      toast({ title: "User Deleted", status: "success" });
    },
    onError: (error) => {
      toast({ title: error.message, status: "error" });
    },
  });

  return (
    <MenuItem
      _hover={{
        bg: "orange.600",
      }}
      bg="orange.800"
      onClick={() => deleteUserMutation.mutate({ id })}
    >
      Delete
    </MenuItem>
  );
};

export default DeleteUser;
