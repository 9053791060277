import { Avatar, Button, Flex, GridItem, Stack, Text } from "@chakra-ui/react";
import React from "react";

const EachTeamUser = ({ user, userIdHandler }) => {
  return (
    <GridItem border="0.1px solid" borderRadius="xl" p="4">
      <Flex gap="4">
        <Avatar size="md" name={user.name} src={user.avatar} />
        <Stack spacing="0">
          <Text fontWeight="bold">{user.name}</Text>
          <Text fontSize="xs">{user.mobile}</Text>
        </Stack>
      </Flex>{" "}
      <Flex justify="end">
        <Button
          size="xs"
          isDisabled={!user.refreshToken || user.blocked}
          colorScheme="green"
          onClick={() => userIdHandler(user._id)}
        >
          {!user.refreshToken || user.blocked
            ? "OTP Login Required"
            : "View Team"}
        </Button>
      </Flex>
    </GridItem>
  );
};

export default EachTeamUser;
