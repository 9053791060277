import * as yup from "yup";

export const validationSchema = yup.object({
  contest: yup.number().typeError("* Only Numbers").required("* Team Required"),
  low: yup
    .number()
    .typeError("* Only Numbers")
    .required("* Lowest Entry Price Required"),
  high: yup
    .number()
    .typeError("* Only Numbers")
    .required("* Highest Entry Price Required"),
  team: yup.number().typeError("* Only Numbers").required("* Team Required"),
  refresh: yup.number().typeError("* Only Numbers").required("* Team Required"),
  contestSize: yup
    .number()
    .typeError("* Only Numbers")
    .required("* Contest Size Required"),
  amount: yup
    .number()
    .typeError("* Only Numbers")
    .required("* Contest Size Required"),
});
