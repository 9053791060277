import {
  Button,
  Center,
  MenuItem,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { InputControl, SubmitButton } from "formik-chakra-ui";
import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { loginVerifyOtp, loginWithOtp } from "../apiCalls/user";
import DreamModal from "./DreamModal";
import { loginVerifyOtpSchema } from "./loginVerifyOtpSchema";

const OTPLoginModal = ({ user }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const cache = useQueryClient();
  const toast = useToast();

  const loginWithOtpMutation = useMutation({
    mutationKey: "loginWithOtp",
    mutationFn: loginWithOtp,
    onSettled: () => {
      toast({ title: "Otp Send", status: "success" });
      cache.invalidateQueries("users");
    },
    onError: (error) => {
      toast({ title: error.message, status: "error" });
    },
  });
  const loginVerifyOtpMutation = useMutation({
    mutationKey: "loginVerifyOtp",
    mutationFn: loginVerifyOtp,
    onSettled: () => {
      onClose();
      toast({ title: "OTP Login Done", status: "success" });
      cache.invalidateQueries("users");
    },
    onError: (error) => {
      toast({ title: error.message, status: "error" });
    },
  });

  const sendOtp = () => {
    loginWithOtpMutation.mutate({
      mobile: user.mobile,
    });
  };

  return (
    <>
      <MenuItem
        onClick={onOpen}
        _hover={{
          bg: "orange.600",
        }}
        bg="orange.800"
      >
        OTP Login
      </MenuItem>
      <DreamModal isOpen={isOpen} onClose={onClose}>
        <Center>
          <Button onClick={sendOtp} isLoading={loginWithOtpMutation.isLoading}>
            {!loginWithOtpMutation.isSuccess ? "OTP Send" : "Send Again"}
          </Button>
        </Center>
        {loginWithOtpMutation.isSuccess && (
          <Formik
            validationSchema={loginVerifyOtpSchema}
            initialValues={{
              mobile: user.mobile,
              otp: "",
            }}
            onSubmit={async (values) => {
              loginVerifyOtpMutation.mutate({
                mobile: values.mobile,
                otp: values.otp,
              });
            }}
          >
            <Form>
              <Stack>
                <InputControl
                  name="otp"
                  label="OTP"
                  inputProps={{
                    focusBorderColor: "orange.400",
                    placeholder: "Enter OTP...",
                  }}
                />

                <SubmitButton
                  isLoading={loginVerifyOtpMutation.isLoading}
                  colorScheme="orange"
                  mt="4"
                  type="submit"
                >
                  Verify OTP
                </SubmitButton>
              </Stack>
            </Form>
          </Formik>
        )}
      </DreamModal>
    </>
  );
};

export default OTPLoginModal;
