import { Badge, Flex, Stack, Text } from "@chakra-ui/react";
import React from "react";
import ScrollableFeed from "react-scrollable-feed";
import ScriptModal from "../../components/ScriptModal";

const LiveResult = ({ isOpen, stopScript, joinStatus, logs }) => {
  return (
    <ScriptModal open={isOpen} onClose={stopScript}>
      <Stack h="65vh">
        <Flex p="4" bg="orange.700" align="center" justify="space-between">
          <Flex>
            <Flex
              width="5"
              height="5"
              bg="white"
              borderRadius="xl"
              align="center"
              justify="center"
              mr="2"
            >
              {joinStatus.joinCounter}
            </Flex>
            <Text color="white">JOINNED MATCH</Text>
          </Flex>
          <Flex
            width="32"
            height="7"
            ml="8"
            bg="white"
            borderRadius="md"
            align="center"
            justify="center"
            mr="2"
          >
            Amount - ₹ {joinStatus.amountCounter}
          </Flex>
        </Flex>
        <ScrollableFeed className={{}}>
          <Stack
            overflowY="auto"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "red",
                borderRadius: "24px",
              },
            }}
          >
            {logs.map((e, i) => {
              return (
                <Badge
                  key={i}
                  ml="2"
                  minW="60%"
                  colorScheme={e.left ? "red" : "green"}
                  p="1"
                  alignSelf={e.left ? "flex-start" : "flex-end"}
                >
                  {e.message}
                </Badge>
              );
            })}
          </Stack>
        </ScrollableFeed>
      </Stack>
    </ScriptModal>
  );
};

export default LiveResult;
