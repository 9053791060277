import React, { useEffect } from "react";
import { useNavigate, Outlet, Navigate } from "react-router";
import { useStateValue } from "../store";

const ProtectedRoute = (props) => {
  const [{ token, user }] = useStateValue();

  const navigate = useNavigate();

  useEffect(() => {
    if (user?.blocked) {
      return navigate("/banned");
    }
  }, [navigate, user]);

  return token ? <Outlet /> : <Navigate replace to="/login" />;
};
export default ProtectedRoute;
