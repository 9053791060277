import { Stack, useToast } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { InputControl, SubmitButton } from "formik-chakra-ui";
import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addUser } from "../apiCalls/user";

const AddUser = ({ onClose }) => {
  const cache = useQueryClient();
  const toast = useToast();

  const { isLoading, mutate } = useMutation({
    mutationKey: ["addUser"],
    mutationFn: addUser,
    onSettled: () => {
      onClose();
      toast({ title: "User Added", status: "success" });
      cache.invalidateQueries("users");
    },
    onError: (error) => {
      toast({ title: error.message, status: "error" });
    },
  });

  return (
    <div>
      <Formik
        initialValues={{ mobile: "", password: "", name: "" }}
        onSubmit={async (values) => {
          mutate({
            mobile: values.mobile,
            password: values.password,
            name: values.name,
          });
        }}
      >
        <Form>
          <Stack>
            <InputControl
              name="name"
              label="Name"
              inputProps={{
                focusBorderColor: "orange.400",
                placeholder: "Enter Name...",
              }}
            />
            <InputControl
              name="mobile"
              label="Mobile"
              inputProps={{
                type: "mobile",
                focusBorderColor: "orange.400",
                placeholder: "Enter Mobile...",
              }}
            />
            <InputControl
              name="password"
              label="Password"
              inputProps={{
                type: "password",
                focusBorderColor: "orange.400",
                placeholder: "Enter Password...",
              }}
            />
            <SubmitButton
              isLoading={isLoading}
              colorScheme="orange"
              mt="4"
              type="submit"
            >
              Add User
            </SubmitButton>
          </Stack>
        </Form>
      </Formik>
    </div>
  );
};

export default AddUser;
