import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  HStack,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import AddUser from "./AddUser";
import DreamModal from "./DreamModal";
import { useQuery } from "@tanstack/react-query";
import { getUsers } from "../apiCalls/user";
import EachUser from "./EachUser";
import { useStateValue } from "../store";
import { Link } from "react-router-dom";

const AdminDashboard = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [{ logout }] = useStateValue();

  const { data, isPending, error } = useQuery({
    queryKey: ["users"],
    queryFn: getUsers,
  });

  if (isPending) return <div>Loading...</div>;
  if (error) return <pre>{error.message}</pre>;

  return (
    <>
      <Stack spacing={0}>
        <HStack
          justify="space-between"
          px="8"
          color="white"
          bg="orange.600"
          w="full"
          h="24"
        >
          <Breadcrumb>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} textDecor="underline" to="/admin">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/admin/team">
                View Team
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Button colorScheme="green" onClick={logout}>
            Logout
          </Button>
        </HStack>

        <Stack bg="blackAlpha.800" minH="calc(100vh - 96px)" p="8">
          <Button alignSelf="end" onClick={onOpen}>
            Add User
          </Button>
          <TableContainer bg="gray.700" color="whiteAlpha.700">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Avatar</Th>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th>Status</Th>
                  <Th isNumeric>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.map((user) => (
                  <EachUser key={user._id} user={user} />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>

      <DreamModal isOpen={isOpen} onClose={onClose} title="Add User">
        <AddUser onClose={onClose} />
      </DreamModal>
    </>
  );
};

export default AdminDashboard;
