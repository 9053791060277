import { Button } from "@chakra-ui/button";

import { Container, Stack } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { Form, Formik } from "formik";
import { InputControl } from "formik-chakra-ui";
import React from "react";
import { useCookies } from "react-cookie";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { signupUser } from "../apiCalls";
import { actionTypes } from "../store/reducer";
import { useStateValue } from "../store/StateProvider";

const Signup = () => {
  const toast = useToast();
  const [, dispatch] = useStateValue();
  const [, setCookie] = useCookies(["jwt"]);

  const navigate = useNavigate();

  const { isLoading, mutateAsync, data } = useMutation({
    queryKey: ["signup"],
    queryFn: signupUser,
    onSuccess: (data) => {
      dispatch({ type: actionTypes.SET_TOKEN, value: data.token });
      setCookie("jwt", data.token);
      navigate("/", {
        replace: true,
      });
    },
    onError: (error) => {
      toast({ title: error.message, status: "error" });
    },
  });

  console.log(data);
  return (
    <>
      <Container
        display="flex"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <Stack width="300px" p="4" boxShadow="xl" borderRadius="xl">
          <Formik
            initialValues={{ mobile: "", password: "", name: "" }}
            onSubmit={async (values) => {
              await mutateAsync({
                mobile: values.mobile,
                password: values.password,
                name: values.name,
              });
            }}
          >
            <Form>
              <InputControl
                name="name"
                label="Name:"
                inputProps={{
                  focusBorderColor: "orange.400",
                  placeholder: "Enter Name...",
                }}
              />
              <InputControl
                name="mobile"
                label="Mobile:"
                inputProps={{
                  type: "mobile",
                  focusBorderColor: "orange.400",
                  placeholder: "Enter Mobile Number...",
                }}
              />

              <InputControl
                name="password"
                label="Password:"
                inputProps={{
                  type: "password",
                  focusBorderColor: "orange.400",
                  placeholder: "Enter Password...",
                }}
              />
              <Button
                isLoading={isLoading}
                colorScheme="orange"
                mt="4"
                type="submit"
              >
                {" "}
                Signup
              </Button>
            </Form>
          </Formik>
        </Stack>
      </Container>
    </>
  );
};

export default Signup;
