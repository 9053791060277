import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useState } from "react";
import NavBar from "../components/Navbar";
import { useStateValue } from "../store";
import MatchTypes from "./MatchTypes";

const MyMatches = () => {
  const [{ logout }] = useStateValue();
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <>
      <NavBar logout={logout} />
      <Tabs onChange={(index) => setTabIndex(index)}>
        <TabList overflowX="auto">
          <Tab>Cricket</Tab>
          <Tab>Football</Tab>
          <Tab>Kabaddi</Tab>
          <Tab>Basketball</Tab>
          <Tab>BaseBall</Tab>
          <Tab>Handball</Tab>
          <Tab>Hockey</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>{tabIndex === 0 && <MatchTypes slug="cricket" />}</TabPanel>
          <TabPanel>
            {tabIndex === 1 && <MatchTypes slug="football" />}
          </TabPanel>
          <TabPanel>{tabIndex === 2 && <MatchTypes slug="kabaddi" />}</TabPanel>
          <TabPanel>{tabIndex === 3 && <MatchTypes slug="nba" />}</TabPanel>
          <TabPanel>
            {tabIndex === 4 && <MatchTypes slug="baseball" />}
          </TabPanel>
          <TabPanel>
            {tabIndex === 5 && <MatchTypes slug="handball" />}
          </TabPanel>
          <TabPanel>{tabIndex === 6 && <MatchTypes slug="hockey" />}</TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default MyMatches;
