import React from "react";
import { Navigate, Outlet, Route } from "react-router";
import { useStateValue } from "../store";

const AdminProtectedRoute = ({ children, path, exact }, props) => {
  const [{ token, user }] = useStateValue();

  // <Route
  //   exact={exact}
  //   path={path}
  //   {...props}
  //   render={() => {
  //     if (token && user?.role === "admin") {
  //       return children;
  //     } else if (token && user?.role !== "user") {
  //       return (
  //         <Route
  //           to="/"
  //           render={() => {
  //             return <Navigate replace to="/" />;
  //           }}
  //         />
  //       );
  //     } else {
  //       return (
  //         <Route
  //           render={() => {
  //             return <Navigate replace to="/login" />;
  //           }}
  //         />
  //       );
  //     }
  //   }}
  // />
  return token && user?.role === "admin" ? (
    <Outlet />
  ) : token && user?.role !== "user" ? (
    <Route
      to="/"
      render={() => {
        return <Navigate replace to="/" />;
      }}
    />
  ) : (
    <Route
      render={() => {
        return <Navigate replace to="/login" />;
      }}
    />
  );
};
export default AdminProtectedRoute;
