import React, { createContext, useContext, useReducer } from "react";
import { useCookies } from "react-cookie";

import { initialState } from "./reducer";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
export const StateContext = createContext([initialState, () => initialState]);

export const StateProvider = ({ reducer, children, initialState }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [cookies, setCookie, removeCookie] = useCookies(["jwt"]);
  const [jwt, setJwt] = useState(cookies.jwt ?? null);
  const [user, setUser] = useState(jwt ? jwtDecode(jwt.token) : null);
  const navigate = useNavigate();
  const logout = () => {
    removeCookie("jwt");
    setJwt(null);
    setUser(null);
    navigate("/login");
  };

  const setToken = (data) => {
    setCookie("jwt", data);
    setJwt((prev) => (prev = data));
    setUser((prev) => (prev = data?.token ? jwtDecode(data?.token) : null));
  };

  return (
    <StateContext.Provider
      value={[{ ...state, token: jwt, user, logout, setToken }, dispatch]}
    >
      {children}
    </StateContext.Provider>
  );
};
export const useStateValue = () => useContext(StateContext);
