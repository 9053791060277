import { MenuItem, useToast } from "@chakra-ui/react";
import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { blockUser } from "../apiCalls/user";

const BlockUser = ({ user }) => {
  const toast = useToast();
  const cache = useQueryClient();

  const blockUserMutation = useMutation({
    mutationKey: ["blockUser"],
    mutationFn: blockUser,
    onSettled: () => {
      cache.invalidateQueries("users");
      toast({ title: "User Blocked", status: "success" });
    },
    onError: (error) => {
      toast({ title: error.message, status: "error" });
    },
  });

  return (
    <MenuItem
      _hover={{
        bg: "orange.600",
      }}
      bg="orange.800"
      onClick={() => blockUserMutation.mutate({ id: user._id })}
    >
      {user.blocked ? "Unblock" : "Block"}
    </MenuItem>
  );
};

export default BlockUser;
