import * as yup from "yup";

export const teamValidationSchema = yup.object({
  site: yup
    .string()
    .typeError("* Enter your game name")
    .required("* Game Required"),
  matchId: yup
    .number()
    .typeError("* Only Numbers")
    .required("* Match Id Required"),
});
