import { Button } from "@chakra-ui/button";
import { Container, Stack } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { Form, Formik } from "formik";
import { InputControl } from "formik-chakra-ui";
import React from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { loginUser } from "../apiCalls";
import { useStateValue } from "../store/StateProvider";
const Login = () => {
  const [{ setToken }] = useStateValue();
  const navigate = useNavigate();
  console.log(import.meta.env);
  const toast = useToast();

  const { isLoading, mutate } = useMutation({
    mutationKey: ["login"],
    mutationFn: loginUser,
    onSuccess: (data) => {
      setToken(data);
      navigate("/", { replace: true });
    },

    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  return (
    <Container
      display="flex"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Stack width="300px" p="4" boxShadow="xl" borderRadius="xl">
        <Formik
          initialValues={{ mobile: "", password: "" }}
          onSubmit={async (values) => {
            mutate({
              mobile: values.mobile,
              password: values.password,
            });
          }}
        >
          <Form>
            <InputControl
              name="mobile"
              label="Mobile:"
              inputProps={{
                type: "mobile",
                focusBorderColor: "orange.400",
                placeholder: "Enter Mobile Number...",
              }}
            />
            <InputControl
              name="password"
              label="Password:"
              inputProps={{
                type: "password",
                focusBorderColor: "orange.400",
                placeholder: "Enter Password...",
              }}
            />
            <Button
              isLoading={isLoading}
              colorScheme="orange"
              mt="4"
              type="submit"
            >
              login
            </Button>
          </Form>
        </Formik>
      </Stack>
    </Container>
  );
};

export default Login;
