import {
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  Td,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import BlockUser from "./BlockUser";
import DeleteUser from "./DeleteUser";
import OTPLoginModal from "./OTPLoginModal";

const EachUser = ({ user }) => {
  return (
    <Tr>
      <Td>
        <Avatar size="sm" name={user.name} src={user.avatar} />
      </Td>
      <Td>{user.name}</Td>
      <Td>{user.mobile}</Td>
      <Td>{user.blocked ? "blocked" : "-"}</Td>
      <Td isNumeric>
        <Menu>
          <MenuButton
            as={Button}
            variant="outline"
            colorScheme="orange"
            rightIcon={<BsThreeDotsVertical />}
          >
            Actions
          </MenuButton>
          <MenuList
            bg="orange.800"
            _focus={{
              bg: "orange.600",
            }}
          >
            <OTPLoginModal user={user} />
            <BlockUser user={user} />
            <DeleteUser id={user._id} />
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
};

export default EachUser;
