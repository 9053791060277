import { Button, Center } from "@chakra-ui/react";
import React from "react";
import { useStateValue } from "../store";

const BlockedUser = () => {
  const [{ logout }] = useStateValue();
  return (
    <Center h="100vh" fontSize="2xl">
      You are banned!!! 😡 <Button onClick={logout}>Logout</Button>
    </Center>
  );
};

export default BlockedUser;
