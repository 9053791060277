import { api } from "./axiosInstance";

export const loginUser = async ({ mobile, password }) => {
  try {
    const { data } = await api.post("/user/signin", { mobile, password });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};
export const signupUser = async ({ mobile, password, name }) => {
  try {
    const { data } = await api.post("/user/signup", { name, mobile, password });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const fetchContestData = async ({
  contest,
  site,
  tourId,
  matchId,
  priceRange,
  team,
  contestSize,
  noContest,
  uid,
  refresh,
}) => {
  console.log(uid);
  try {
    const { data } = await api.post(`/league`, {
      contest,
      site,
      tourId,
      matchId,
      priceRange,
      team,
      contestSize,
      noContest,
      uid,
      refresh,
    });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const addCookie = async ({ userId, csrf, cookie }) => {
  try {
    const { data } = await api.patch(`/cookie/${userId}`, {
      csrf,
      cookie,
    });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const getMatchBySlug = async ({ userId, slug }) => {
  try {
    const { data } = await api.get(`/league/my-match/${userId}/${slug}`);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const getMatchData = async ({ userId, site, matchId, live }) => {
  try {
    const { data } = await api.get(
      `/league/match-data/${userId}/${site}/${matchId}/${live}`
    );
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const getMatchContests = async ({ tourId, matchId, site }) => {
  try {
    const { data } = await api.post(`/league/match-contests`, {
      tourId,
      matchId,
      site,
    });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};
export const getTeamData = async ({
  uid,
  site,
  matchId,
  teamId,
  contestId,
}) => {
  try {
    const { data } = await api.post(`/league/team-data`, {
      uid,
      site,
      matchId,
      teamId,
      contestId,
    });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};
