import {
  Avatar,
  AvatarBadge,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Grid,
  Heading,
  HStack,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { InputControl, SelectControl, SubmitButton } from "formik-chakra-ui";
import React, { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { getTeamData } from "../../apiCalls";
import { getUsers } from "../../apiCalls/user";
import { useStateValue } from "../../store";
import EachTeamUser from "./EachTeamUser";
import { teamValidationSchema } from "./TeamValidationSchema";

const AdminTeam = () => {
  const [userId, setUserId] = useState(null);
  const toast = useToast();

  const [{ logout }] = useStateValue();

  const teamDataMutation = useMutation({
    mutationKey: ["matchData", userId],
    mutationFn: getTeamData,
    onError: (error) => {
      toast({ title: error.message, status: "error" });
    },
  });

  const { data, isPending, error } = useQuery({
    queryKey: ["users"],
    queryFn: getUsers,
  });

  if (isPending) return <div>Loading...</div>;
  if (error) return <pre>{error.message}</pre>;

  const userIdHandler = (id) => {
    setUserId(id);
  };
  // return null;
  if (isPending) return <div>Loading...</div>;

  return (
    <>
      <Stack spacing={0}>
        <HStack
          justify="space-between"
          px="8"
          color="white"
          bg="orange.600"
          w="full"
          h="24"
        >
          <Breadcrumb>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/admin">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink as={Link} textDecor="underline" to="/admin/team">
                View Team
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Button colorScheme="green" onClick={logout}>
            Logout
          </Button>
        </HStack>

        <Stack
          bg="blackAlpha.800"
          minH="calc(100vh - 96px)"
          p="8"
          color="white"
        >
          <Formik
            validationSchema={teamValidationSchema}
            initialValues={{
              matchId: "",
              site: "",
            }}
            onSubmit={(value) => {
              teamDataMutation.mutate({
                uid: userId,
                site: value.site,
                matchId: value.matchId,
                contestId: 4,
                teamId: 1,
              });
            }}
          >
            <Form>
              <HStack spacing={0} gap="8" py="8">
                <SelectControl
                  name="site"
                  label="Game Type"
                  selectProps={{
                    placeholder: "Select Game Type",
                  }}
                >
                  <option value="cricket">Cricket</option>
                  <option value="football">Football</option>
                  <option value="kabaddi">Kabaddi</option>
                  <option value="nba">Basketball</option>
                  <option value="baseball">BaseBall</option>
                  <option value="handball">HandBall</option>
                  <option value="hockey">Hockey</option>{" "}
                </SelectControl>

                <InputControl
                  name="matchId"
                  label="Match ID:"
                  inputProps={{ type: "number" }}
                />
              </HStack>

              <Flex justify="end" pb="8">
                {userId && (
                  <SubmitButton
                    isLoading={teamDataMutation.isLoading}
                    colorScheme="orange"
                  >
                    Fetch Team
                  </SubmitButton>
                )}
              </Flex>
            </Form>
          </Formik>

          <Stack>
            <Heading fontSize="xl">
              Team Name - {teamDataMutation?.data?.match.userTeam.name}
            </Heading>

            {teamDataMutation?.data?.match?.userTeam?.players.map((player) => {
              return (
                <HStack key={player.id}>
                  <Avatar src={player.artwork[0].src} size="sm">
                    {player.role && (
                      <AvatarBadge
                        boxSize="5"
                        fontSize="8px"
                        bg={
                          player.role.shortName === "C"
                            ? "green.500"
                            : "orange.500"
                        }
                      >
                        {player.role.shortName}
                      </AvatarBadge>
                    )}
                  </Avatar>
                  <Text fontSize="xs">Player {player.name}</Text>
                  <Badge fontSize="xx-small">Cr {player.credits}</Badge>
                </HStack>
              );
            })}
          </Stack>

          <Grid
            w="full"
            gap={{ base: 2, md: 6 }}
            gridTemplateColumns="repeat(auto-fit,minmax(320px,1fr))"
          >
            {data?.map((user) => (
              <EachTeamUser
                userIdHandler={userIdHandler}
                key={user._id}
                user={user}
              />
            ))}
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default AdminTeam;
