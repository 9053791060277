import {
  Accordion,
  Avatar,
  Box,
  Center,
  Flex,
  HStack,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getMatchBySlug } from "../apiCalls";
import { useStateValue } from "../store";
import { Link } from "react-router-dom";
import MatchJoinCard from "../Home/components/MatchJoinCard";
const MatchTypes = ({ slug }) => {
  const [{ token }] = useStateValue();
  const toast = useToast();

  const { data, isPending, error, isError } = useQuery({
    queryKey: [slug],
    queryFn: () => getMatchBySlug({ userId: token.userId, slug }),
    enabled: !!token,
  });

  if (isError) {
    return (
      <Center h="80vh" color="red">
        {error.message}
      </Center>
    );
  }

  const upcommingMatches = data?.site?.fixtureMatches?.edges;
  const liveMatches = data?.site?.liveMatches?.edges;
  const completeMatches = data?.site?.resultsMatches?.edges;

  return (
    <>
      {isPending ? (
        <Spinner />
      ) : (
        <>
          {data && (
            <Tabs>
              <TabList>
                <Tab>Upcomming</Tab>
                <Tab>Live</Tab>
                <Tab>Complete</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  {upcommingMatches?.length <= 0 ? (
                    <Center mt="24">
                      <Text>No Upcomming Matches</Text>
                    </Center>
                  ) : (
                    <Accordion>
                      {upcommingMatches?.map((e) => (
                        <MatchJoinCard
                          match={e}
                          key={e.id}
                          site={slug}
                          live={false}
                        />
                      ))}
                    </Accordion>
                  )}
                </TabPanel>
                <TabPanel>
                  {liveMatches?.length <= 0 ? (
                    <Center mt="24">
                      <Text>No Live Matches</Text>
                    </Center>
                  ) : (
                    <>
                      {liveMatches?.map((e) => (
                        <MatchCard
                          match={e}
                          key={e.id}
                          site={slug}
                          live={true}
                        />
                      ))}
                    </>
                  )}
                </TabPanel>
                <TabPanel>
                  {completeMatches?.length <= 0 ? (
                    <Center mt="24">
                      <Text>No Completed Matches</Text>
                    </Center>
                  ) : (
                    <>
                      {completeMatches?.map((e) => (
                        <MatchCard
                          match={e}
                          key={e.id}
                          site={slug}
                          live={true}
                        />
                      ))}
                    </>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}
        </>
      )}
    </>
  );
};

export default MatchTypes;

const MatchCard = ({ match, site, live }) => {
  return (
    <Link
      to={`/match-data/${site}/${match.id}/${live}/${match.name}/${match.tour.name}/${match.joinedContestsCount}`}
    >
      <Box padding="4" boxShadow="md" borderRadius="xl" mb="4">
        <Stack justify="space-between">
          <HStack>
            <Avatar w="8" h="8" src={match.squads[0].flag[0].src} />
            <Text> {match.name}</Text>
            <Avatar w="8" h="8" src={match.squads[1].flag[0].src} />
          </HStack>
          <Text>{match.tour.name}</Text>
        </Stack>
        <Flex mt="4">
          <Tag colorScheme="red">Joined- {match.joinedContestsCount}</Tag>
        </Flex>
      </Box>
    </Link>
  );
};
