import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import Login from "./Login/Login";
import Signup from "./Signup/Signup";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useStateValue } from "./store/StateProvider";

import MyMatches from "./MyMatches/MyMatches";
import MatchResult from "./MatchResults/MatchResult";
import ProtectedRoute from "./components/ProtectedRoutes";
import AdminDashboard from "./Admin/AdminDashboard";
import AdminProtectedRoute from "./components/AdminProtectedRoute";
import BlockedUser from "./BlockedUser/BlockedUser";
import AdminTeam from "./Admin/AdminTeam/AdminTeam";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
const queryClient = new QueryClient();

const App = () => {
  const [{ token }] = useStateValue();

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route
          path="/login"
          element={
            !token ? (
              <Login />
            ) : (
              <Route render={() => <Navigate replace to="/" />} />
            )
          }
        />
        <Route element={<AdminProtectedRoute />}>
          <Route path="/admin/team" element={<AdminTeam />} />
          <Route path="/admin" element={<AdminDashboard />} />
        </Route>

        <Route path="/banned" element={<BlockedUser />} />

        <Route
          path="/signup"
          element={
            !token ? (
              <Signup />
            ) : (
              <Route render={() => <Navigate replace to="/" />} />
            )
          }
        />

        <Route element={<ProtectedRoute />}>
          <Route
            path="/match-data/:site/:matchId/:live/:matchName/:tourName/:joinedCount"
            element={<MatchResult />}
          />

          <Route path="/" element={<MyMatches />} />
        </Route>

        <Route render={() => <Navigate replace to="/login" />} />
      </Routes>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export default App;
