import { Button } from "@chakra-ui/button";

import { Container, Flex } from "@chakra-ui/layout";
import {
  Avatar,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
} from "@chakra-ui/react";
import { useStateValue } from "../store";

const NavBar = ({ logout }) => {
  const [{ token }] = useStateValue();

  return (
    <>
      <Container
        as="nav"
        maxW="full"
        display="flex"
        justifyContent="space-between"
        padding="4"
        boxShadow="md"
        pos="sticky"
        top="0"
        zIndex="50"
        bg="white"
        mb="4"
      >
        <Stack width="100%">
          <Flex align="center" justify="space-between">
            <Heading color="red">Dream 11</Heading>{" "}
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton as="div">
                    {token?.name && (
                      <Avatar
                        boxShadow={isOpen ? "ring" : "none"}
                        boxSize="10"
                        name={token?.name}
                      />
                    )}
                  </MenuButton>
                  <MenuList
                    borderRadius="md"
                    overflow="hidden"
                    boxShadow="md"
                    minW="40"
                  >
                    {" "}
                    <MenuItem px="4" py="2">
                      User : {token?.name}
                    </MenuItem>
                    <MenuItem as="div" px="4" py="2">
                      <Button onClick={logout}> Sign out </Button>
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          </Flex>
        </Stack>
      </Container>
    </>
  );
};

export default NavBar;
