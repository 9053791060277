import { api } from "./axiosInstance";

const base = "/admin/user";
const loginBase = "/otp";

export const getUsers = async () => {
  try {
    const { data } = await api.get(`${base}/users`);
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const addUser = async ({ mobile, password, name }) => {
  try {
    const { data } = await api.post(`${base}/add`, {
      name,
      mobile,
      password,
    });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const loginWithOtp = async ({ mobile }) => {
  try {
    const { data } = await api.post(`${loginBase}/login-with-otp`, {
      mobile,
    });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};
export const loginVerifyOtp = async ({ mobile, otp }) => {
  try {
    const { data } = await api.post(`${loginBase}/login-verify-otp`, {
      mobile,
      otp,
    });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const deleteUser = async ({ id }) => {
  try {
    const { data } = await api.post(`${base}/delete`, {
      id,
    });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const blockUser = async ({ id }) => {
  try {
    const { data } = await api.post(`${base}/block`, {
      id,
    });
    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};
