import { Box, Flex, Grid } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { InputControl, SelectControl, SubmitButton } from "formik-chakra-ui";
import { PersistFormikValues } from "formik-persist-values";
import React from "react";
import { useStateValue } from "../../store";
import { validationSchema } from "../../validation";
import { useQuery } from "@tanstack/react-query";
import { getMatchContests } from "../../apiCalls";

const MatchForm = ({ onOpen, socket, site, tourId, matchId }) => {
  const [{ token }] = useStateValue();

  const matchContestQuery = useQuery({
    queryKey: ["matchContest", site, tourId, matchId],

    queryFn: () =>
      getMatchContests({
        site,
        tourId,
        matchId,
      }),
  });

  return (
    <Grid>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          contest: 0,
          low: "",
          high: "",
          noContest: 1,
          team: 1,
          contestSize: 0,
          refresh: 1000,
          amount: 100,
        }}
        onSubmit={async (values) => {
          onOpen();
          const priceRange = { low: values.low, high: values.high };
          socket.emit("join", {
            contest: parseInt(values.contest),
            site: site,
            tourId: tourId,
            priceRange: priceRange,
            contestSize: parseInt(values.contestSize),
            matchId: matchId,
            team: parseInt(values.team),
            noContest: parseInt(values.noContest),
            uid: token.userId,
            refresh: values.refresh,
            amount: parseInt(values.amount),
          });
        }}
      >
        {({ values }) => (
          <Form>
            <Flex
              mb="4"
              justify="space-between"
              direction={{ base: "column", md: "row" }}
            >
              <Box mr={{ base: 0, md: 4 }}>
                {" "}
                <SelectControl
                  name="contest"
                  label="Contest Section:"
                  selectProps={{
                    placeholder: "Select Contest Type",
                  }}
                >
                  {matchContestQuery.data &&
                    matchContestQuery.data.contestSections.map(
                      (contestSection) => (
                        <option
                          key={contestSection.id}
                          value={contestSection.id}
                        >
                          {contestSection.name}
                        </option>
                      )
                    )}
                </SelectControl>
              </Box>
            </Flex>

            <Flex gap="4" mb="4">
              <InputControl
                name="low"
                label="EntrySP:"
                inputProps={{ type: "number" }}
              />
              <InputControl
                name="high"
                label="EntryEP:"
                inputProps={{ type: "number" }}
              />
            </Flex>
            <Flex gap="4" mb="4">
              <SelectControl
                name="team"
                label="Team"
                selectProps={{
                  placeholder: "Select Team",
                }}
              >
                <option value={1}>Team 1 </option>
                <option value={2}>Team 2</option>
                <option value={3}>Team 3</option>
              </SelectControl>
              <InputControl
                name="amount"
                label="Amount:"
                inputProps={{ type: "number" }}
              />

              <SelectControl
                name="contestSize"
                label="Slot"
                selectProps={{
                  placeholder: "Select Slot ..... ",
                }}
              >
                <option value={0}>0</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
              </SelectControl>
            </Flex>
            <Flex gap="4">
              <InputControl
                name="noContest"
                label="No of Contest:"
                inputProps={{ type: "number" }}
              />

              <InputControl
                name="refresh"
                label="Refresh ( 1s = 1000ms):"
                inputProps={{ type: "number" }}
              />
            </Flex>
            <SubmitButton mt="4" colorScheme="red" type="submit">
              Start
            </SubmitButton>
            {token && <PersistFormikValues name={token.userId} />}
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default MatchForm;
