import React from "react";
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  // ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from "@chakra-ui/react";

const ScriptModal = ({ open, onClose, children, modalSize = "3xl" }) => {
  return (
    <ChakraModal isOpen={open} onClose={onClose} size={modalSize}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p="0">{children}</ModalBody>
        <ModalFooter>
          <Button colorScheme="red" onClick={onClose}>
            Stop Script
          </Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export default ScriptModal;
