import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Flex,
  HStack,
  Stack,
  Tag,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useStateValue } from "../../store";
import LiveResult from "./LiveResult";
import MatchForm from "./MatchForm";
const socket = io(
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BACKEND
    : process.env.REACT_APP_PROD,
  { query: "foo=bar", transports: ["websocket"], upgrade: false }
);
const MatchJoinCard = ({ match, site, live }) => {
  const [{ token }] = useStateValue();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [joinStatus, setJoinStatus] = useState({
    joinCounter: 0,
    amountCounter: 0,
  });
  const [logs, setLogs] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    socket.on("connection", () => {
      console.log("connected to server");
    });

    return () =>
      socket.on("disconnect", () => {
        console.log("user left");
      });
  }, [token]);

  const toast = useToast();
  useEffect(() => {
    socket.on("error", ({ error }) => {
      setError(error);
    });
  }, []);
  useEffect(() => {
    socket.on("join-status", ({ counter, amountCounter }) => {
      setJoinStatus({ joinCounter: counter, amountCounter: amountCounter });
    });
  }, []);

  useEffect(() => {
    socket.on("logs", ({ message, left }) => {
      setLogs((prev) => [...prev, { message, left }]);
    });
  }, []);
  console.log(logs);
  if (error) {
    toast({ title: error, status: "error" });
    setError(null);
    setJoinStatus({ joinCounter: 0, amountCounter: 0 });
    setLogs([]);
    onClose();
  }

  const stopScript = () => {
    socket.emit("stop-script", () => {});
    setJoinStatus({ joinCounter: 0, amountCounter: 0 });
    setLogs([]);
    onClose();
  };
  useEffect(() => {
    socket.on("success", ({ message }) => {
      toast({ title: message, status: "success" });
    });
  }, [toast]);

  return (
    <>
      {" "}
      <LiveResult
        isOpen={isOpen}
        stopScript={stopScript}
        joinStatus={joinStatus}
        logs={logs}
      />
      <AccordionItem padding="1" boxShadow="md" borderRadius="xl">
        <Stack align="start" as={AccordionButton}>
          <HStack>
            <Avatar w="8" h="8" src={match.squads[0].flag[0].src} />
            <Text> {match.name}</Text>
            <Avatar w="8" h="8" src={match.squads[1].flag[0].src} />
          </HStack>
          <Text>{match.tour.name}</Text>
          <Flex mt="4">
            <Tag colorScheme="red">Joined- {match.joinedContestsCount}</Tag>
          </Flex>
        </Stack>
        <AccordionPanel pb={4}>
          <MatchForm
            onOpen={onOpen}
            socket={socket}
            matchId={match.id}
            site={site}
            tourId={match.tour.id}
          />
        </AccordionPanel>
      </AccordionItem>
    </>
  );
};
export default MatchJoinCard;
