import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Stack,
  Tag,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getMatchData } from "../apiCalls";
import { useStateValue } from "../store";
const MatchResult = () => {
  const { matchId, site, live, matchName, tourName, joinedCount } = useParams();
  const [{ token }] = useStateValue();
  const toast = useToast();

  const { data, isLoading, error, isError, mutateAsync } = useMutation({
    mutationKey: [matchId],
    mutationFn: getMatchData,
  });

  const calMatchData = async () => {
    await mutateAsync({ userId: token.userId, site, matchId, live });
  };

  const calProfitLoss = (invest, win, type) => {
    const netAmount = (win.toFixed(2) - invest.toFixed(2)).toFixed(2);
    const percentage = (
      (netAmount / (invest === 0 ? 1 : invest)) *
      100
    ).toFixed(2);

    return (
      <Box boxShadow="md" p="2">
        <Divider mt="4" />
        <Text textAlign="center" fontWeight="bold" fontSize="lg" mb="4">
          {type === "Total"
            ? " All Joined Contest"
            : `    Contest Size - ${type}`}
        </Text>
        <Flex>
          <Text mr="4">Invested Amount - ₹{invest.toFixed(2)}</Text>
          <Text>Wining Amount- ₹{win.toFixed(2)}</Text>
        </Flex>
        <Flex>
          <Text
            mr="4"
            color={netAmount >= 0 ? "green" : "red"}
            fontWeight="bold"
          >
            {netAmount >= 0 ? `Profit: ₹${netAmount}` : `Loss : ₹${netAmount}`}
          </Text>
          <Text
            mr="4"
            color={netAmount >= 0 ? "green" : "red"}
            fontWeight="bold"
          >
            {netAmount >= 0 ? `${percentage}%` : `${percentage}%`}
          </Text>
        </Flex>
      </Box>
    );
  };

  const contestSize4 = data
    ? calProfitLoss(data?.size4?.invest, data?.size4?.win, "4")
    : null;
  const contestSize3 = data
    ? calProfitLoss(data?.size3?.invest, data?.size3?.win, "3")
    : null;
  const restContest = data
    ? calProfitLoss(data?.rest?.invest, data?.rest?.win, "Rest")
    : null;
  const total = data
    ? calProfitLoss(data?.total?.invest, data?.total?.win, "Total")
    : null;
  if (isError) {
    toast({ title: error.message, status: "error" });
  }

  return (
    <Box>
      <Box padding="4" boxShadow="md" borderRadius="xl" mb="4">
        <Flex justify="space-between">
          <Text> {matchName}</Text>
          <Text>{tourName}</Text>
        </Flex>
        <Flex mt="4" justify="space-between">
          <Tag colorScheme="red">Joined- {joinedCount}</Tag>

          <Button isLoading={isLoading} onClick={calMatchData}>
            Calculate
          </Button>
        </Flex>
      </Box>
      <Box mt="4" padding="4" boxShadow="md" borderRadius="xl">
        <Heading fontSize="xl" textAlign="center">
          Match Results
        </Heading>

        <Stack>{contestSize4}</Stack>
        <Stack>{contestSize3}</Stack>
        <Stack>{restContest}</Stack>
        <Stack>{total}</Stack>
      </Box>
    </Box>
  );
};

export default MatchResult;
